import React from "react";
import "../styles/Showcase.sass";
import waves from "../img/wave.png";

const Showcase = ({ isDarkMode }) => {
  const shouldUseWhiteGradiantBg = isDarkMode ? "useWhiteGradiantBg" : "";
  return (
    <div className="Showcase">
      <div className="Showcase-container ">
        <img src={waves} alt="waves" />
        <div className="container Showcase-info">
          <h3 className="wow fadeInDown" data-wow-delay="1.2s">
            Full-Stack Developer
          </h3>
          <h1 className="wow fadeIn" data-wow-delay=".5s">
            KEVIN MONTERO
          </h1>
          <p
            className={`wow fadeIn ${shouldUseWhiteGradiantBg} text-2`}
            data-wow-delay=".5s"
          >
            I am a Full-Stack Developer who likes to bring ideas to life with
            elegant, attractive, and enjoyable design. I love to share my
            knowledge with others and learn new things about technology every
            day.
          </p>
          <a
            activeClass="active"
            href="https://drive.google.com/file/d/1Cd86qhnLDcF5zk-e0BY9LHDjwVrmVsn9/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="wow fadeIn resume-btn"
            data-wow-delay=".5s"
          >
            View Resume
          </a>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
